import { QueryHookOptions } from '@apollo/client';
import { Article, UserArticleRelation } from 't2-graphql-types';
import { useT2Query } from 'src/hooks/useT2Query';
import { getReadUserArticleRelationsByArticleIds } from '../queries/getReadUserArticleRelationsByArticleIds';

type TVariables = {
  articleIds: string[];
  userId: string;
  skip?: number;
  take?: number;
};
type TData = {
  userArticleRelations: (UserArticleRelation & { article: Pick<Article, 'id'> })[];
};

export const useReadUserArticleRelationsByArticleIds = (options: QueryHookOptions<TData, TVariables>) =>
  useT2Query<TData, TVariables>(getReadUserArticleRelationsByArticleIds, {
    ...options,
    skip: !options.variables?.articleIds.length || !options.variables.userId,
  });
