import { gql } from '@apollo/client';
import { ARTICLE_CARD_FIELDS } from 'src/components/TerritoryArticlesSection/queries/articleCardDataFragment';

export const getRecommendedPostsWithoutUserRelationsQuery = gql`
  ${ARTICLE_CARD_FIELDS}
  query getRecommendedPostsWithoutUserRelationsQuery($skip: Int!, $take: Int!) {
    listing(where: { name: RecommendedPosts }) {
      articlesCount
      articles(skip: $skip, take: $take) {
        ...ArticleCardFields
      }
    }
  }
`;
