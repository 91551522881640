import { gql } from '@apollo/client';

export const getReadUserArticleRelationsByArticleIds = gql`
  query getReadUserArticleRelationsByArticleIds($userId: ID!, $articleIds: [ID!], $skip: Int, $take: Int) {
    userArticleRelations(
      where: {
        user: { id: { equals: $userId } }
        article: { id: { in: $articleIds } }
        relationType: { equals: read }
        percentReadTime: { gt: "0.5" }
      }
      skip: $skip
      take: $take
    ) {
      id
      percentReadTime
      relationType
      article {
        id
      }
    }
  }
`;
