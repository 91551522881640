import React, { FC } from 'react';
import { Article } from 't2-graphql-types';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { getArticlesCardData } from '../ArticleCard/services/getArticlesCardData';
import { ListSection } from '../ListSection/ListSection';
import { Pagination } from '../Pagination/Pagination';
import Typo from '../Typography/Typo';
import { PaginationPurpose } from '../Pagination/constants';
import { PostCard } from '../PostCard/PostCard';
import { PostCardLoader } from '../PostCard/PostCardLoader';
import { TPostCardProps } from '../PostCard/types';

type TPaginatedArticlesListProps = {
  listSection: {
    title?: string;
    className?: string;
    loading: boolean;
    pagination?: {
      purpose?: PaginationPurpose;
    };
  };
  content: {
    component?: FC<TPostCardProps>;
    items: Article[];
    itemCount: number;
    emptyMessage: string;
    onItemClick?: (articleId: string) => void;
    refetch: Function;
    error: boolean;
    loading: boolean;
  };
  lens: {
    loading: boolean;
  };
  showCounter?: boolean;
  pageSize: number;
};

export const PaginatedArticlesList: FC<TPaginatedArticlesListProps> = ({
  listSection,
  content,
  showCounter = true,
  pageSize,
}) => {
  const Component = content.component || PostCard;
  const renderArticleCardLoaders = () =>
    // eslint-disable-next-line react/no-array-index-key
    Array.from({ length: pageSize }, (_, index) => (
      <div key={index}>
        <PostCardLoader />
      </div>
    ));

  const renderListSectionBody = () => {
    if (content.loading) {
      return renderArticleCardLoaders();
    }

    const cardData = getArticlesCardData(content.items || []);

    if (isEmpty(cardData)) {
      return (
        <Typo variant="p2" className="flex mt-2 mb-4 md:mt-10 md:mb-16 col-span-full justify-self-center">
          {content.emptyMessage}
        </Typo>
      );
    }

    return cardData.map((item) => <Component article={item} key={item.id} />);
  };

  return (
    <ListSection
      className={classNames('w-full col-span-full lg:col-start-2 lg:col-span-10 ', listSection.className)}
      titleClassName="mb-7"
      titleText={listSection.title}
      loading={listSection.loading}
      totalItems={content.itemCount}
      showCounter={showCounter}
      pagination={
        <Pagination
          pageSize={pageSize}
          totalItems={content.itemCount}
          refetch={content.refetch}
          loading={content.loading}
          error={content.error}
          purpose={listSection?.pagination?.purpose}
        />
      }
    >
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5">{renderListSectionBody()}</div>
    </ListSection>
  );
};
